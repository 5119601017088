import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Breadcrumb {
  label?: string;
  alias?: string;
  url: string;
  avatarDocId?: string;
  avatarName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const breadcrumbs = this.buildBreadcrumbs(this.route.root);
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private buildBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    for (const child of children) {
      const routeUrl = child.snapshot.url
        .map(segment => segment.path)
        .join('/');
      const newUrl = routeUrl ? `${url}/${routeUrl}` : url;

      const breadcrumbData = child.snapshot.data['breadcrumb'];
      if (breadcrumbData) {
        const exists = breadcrumbs.some(b => b.url === newUrl);
        if (!exists) {
          breadcrumbs.push({
            label: breadcrumbData.label,
            alias: breadcrumbData.alias,
            url: newUrl,
          });
        }
      }
      // Recursively add breadcrumbs for deeper levels
      this.buildBreadcrumbs(child, newUrl, breadcrumbs);
    }

    return breadcrumbs;
  }

  updateBreadcrumbLabel(alias: string, newLabel: string): void {
    const breadcrumbs = this._breadcrumbs$.getValue().map(breadcrumb => {
      if (breadcrumb.alias === alias) {
        return { ...breadcrumb, label: newLabel };
      }
      return breadcrumb;
    });

    this._breadcrumbs$.next(breadcrumbs);
  }

  updateBreadcrumbAvatar(
    alias: string,
    avatarDocId?: string,
    avatarName?: string
  ): void {
    const breadcrumbs = this._breadcrumbs$.getValue().map(breadcrumb => {
      if (breadcrumb.alias === alias) {
        return { ...breadcrumb, avatarDocId, avatarName };
      }
      return breadcrumb;
    });

    this._breadcrumbs$.next(breadcrumbs);
  }

  clearBreadcrumbs(): void {
    this._breadcrumbs$.next([]);
  }
}
