import { createReducer, on, createFeature } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export interface AuthState {
  isLoading: boolean;
  wsInitToken: string | null;
}

const initialState: AuthState = {
  isLoading: false,
  wsInitToken: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.getWSInitToken, state => ({
    ...state,
    wsInitToken: null,
  })),
  on(AuthActions.getWSInitTokenSuccess, (state, { payload }) => ({
    ...state,
    wsInitToken: payload,
  })),
  on(AuthActions.getWSInitTokenError, state => ({
    ...state,
    wsInitToken: null,
  })),
  on(AuthActions.login, state => ({
    ...state,
    isLoading: true,
  })),
  on(AuthActions.loginOk, state => ({
    ...state,
    isLoading: false,
  })),
  on(AuthActions.loginError, state => ({
    ...state,
    isLoading: false,
  }))
);

export const AuthFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
});

export const { selectIsLoading, selectWsInitToken } = AuthFeature;
