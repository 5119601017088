/**
 * Check if provided value is null or undefined.
 *
 * @param value Value to check.
 */
export function isNil(value: any): boolean {
  return value == null;
}

export function isNonNullable<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}

export function isNullable<T>(
  value: T | undefined | null
): value is undefined | null {
  return value === null || value === undefined;
}
