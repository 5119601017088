/** DO NOT EDIT THIS FILE! */
import { Version } from '@angular/core';
import { Bundle } from '@qtek/shared/models';

export const VERSION = new Version('1.35.33');

export const BUNDLE: Bundle = {
  date: 1737896812215,
  commitSha: 'e60ccd19d9255a826131cca90952affe31f51a67',
  version: VERSION,
};
