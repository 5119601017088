/**
 * This file contains providers for APP_INITIALIZER token.
 *
 * Each funciton will be executed before application starts.
 */
import {
  EnvironmentProviders,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AppState, MetaActions } from '@qtek/libs/store';
import { firstValueFrom } from 'rxjs';
import { Pwa } from './pwa.service';

/**
 * Load meta data and put it into the store.
 */
export function LoadMetaDataFactory(store: Store<AppState>, actions$: Actions) {
  return () => {
    store.dispatch(MetaActions.loadMetaGuiDomain());

    return firstValueFrom(
      actions$.pipe(ofType(MetaActions.loadMetaStates), take(1))
    );
  };
}

export function initPromptEvent() {
  const pwa = inject(Pwa);
  return () => {
    pwa.init();
  };
}

export const APP_INITIALIZER_PROVIDERS: EnvironmentProviders[] = [
  // {
  //   provide: APP_INITIALIZER,
  //   multi: true,
  //   useFactory: LoadMetaDataFactory,
  //   deps: [Store, Actions],
  // },
  provideAppInitializer(() => {
    const initializerFn = initPromptEvent();
    return initializerFn();
  }),
];
