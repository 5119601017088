import { createFeature, createReducer, on } from '@ngrx/store';
import { PermissionsActions } from './permissions.actions';

export interface PermissionsState {
  permissions: Set<string>;
}

export const permissionsState: PermissionsState = {
  permissions: new Set<string>(),
};

export const PermissionsReducer = createReducer(
  permissionsState,
  on(PermissionsActions.setPermissions, (state, { permissions }) => ({
    ...state,
    permissions: new Set(permissions),
  }))
);

export const PermissionsFeature = createFeature({
  name: 'Permissions',
  reducer: PermissionsReducer,
});

export const { selectPermissions } = PermissionsFeature;
