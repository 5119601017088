import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslationCoreModule } from '@qtek/libs/translation-core';

export const informationDialogConfig: MatDialogConfig = {
  panelClass: 'fullscreen',
  width: '300px',
  autoFocus: true,
};
@Component({
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslationCoreModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class InformationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { information: string; title: string },
    private matDialogRef: MatDialogRef<InformationDialogComponent>
  ) {}

  public ok(): void {
    this.matDialogRef.close();
  }
}
