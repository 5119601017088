import { createActionGroup, props } from '@ngrx/store';
import { Message } from '@qtek/shared/models';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Get Current User': props<{
      payload?: any;
    }>(),
    'Get Current User Success': props<{ payload: any }>(),
    'Get Current User Failure': props<{ payload: any }>(),

    'Update Current User': props<{ payload: any }>(),
    'Update Current User Success': props<{ payload: any; message: Message }>(),
    'Update Current User Failure': props<{ payload: any }>(),

    'Update Current User Company': props<{
      payload: { form?: any; id?: string; additionalPayload?: any };
    }>(),
    'Update Current User Company Success': props<{
      payload: any;
      message: Message;
    }>(),
    'Update Current User Company Failure': props<{ payload: any }>(),

    'Create Company': props<{ payload: any }>(),
    'Create Company Success': props<{ payload: any }>(),
    'Create Company Failure': props<{ payload: any }>(),

    'Get Current User Multi Company': props<{ payload: any }>(),
    'Get Current User Companies': props<{ payload?: any }>(),
    'Get Current User Companies Success': props<{ payload: any }>(),
    'Get Current User Companies Failure': props<{ payload: any }>(),

    'Delete Current User Company': props<{
      payload: { id: string; force?: boolean };
    }>(),
    'Delete Current User Company Success': props<{
      payload: string;
      message: Message;
    }>(),
    'Delete Current User Company Failure': props<{ payload?: any }>(),
  },
});
