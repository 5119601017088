import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { AUTH_USER_KEY, initialAuthUserState } from '../models/auth-user.model';
import { AuthUserActions } from './auth-user.actions';

export const AuthUserReducer = createReducer(
  initialAuthUserState,
  on(AuthUserActions.logout, state => {
    return {
      ...state,
      user: null,
    };
  }),
  on(AuthUserActions.getUserSuccess, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(AuthUserActions.getUserFailure, state => {
    return {
      ...state,
      user: null,
    };
  })
);

export const AuthUserFeature = createFeature({
  name: AUTH_USER_KEY,
  reducer: AuthUserReducer,
  extraSelectors: ({ selectUser }) => ({
    isAuthorized: createSelector(selectUser, user => !!user?.prs?.prsId),
    getPersonProgress: createSelector(selectUser, user =>
      user.prs && user.prs.bitMsks && user.prs.bitMsks._prgs
        ? user.prs.bitMsks._prgs
        : 0
    ),
    getCompanyProgress: createSelector(selectUser, user =>
      user.acn && user.acn.bitMsks && user.acn.bitMsks._prgrs
        ? user.acn.bitMsks._prgrs
        : 0
    ),
    getDemoDataProgress: createSelector(selectUser, user =>
      user.acn && user.acn.bitMsks && user.acn.bitMsks._ddb
        ? user.acn.bitMsks._ddb
        : 0
    ),
    getTutorialProgress: createSelector(selectUser, user =>
      user.prs && user.prs.bitMsks && user.prs.bitMsks._uitut
        ? user.prs.bitMsks._uitut
        : undefined
    ),
    getTheme: createSelector(selectUser, user => user?.rel?.theme ?? ''),
  }),
});
