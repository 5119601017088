import { MetaReducer, ActionReducer } from '@ngrx/store';
import { AuthActions } from './auth';

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === AuthActions.logout.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any>> = [logout];
