import { PaymentPlan } from '@qtek/shared/models';
import { createReducer, on, createFeature } from '@ngrx/store';
import { PaymentPlanActions } from './payment-plan.actions';

export interface PaymentPlanState {
  plans: PaymentPlan[];
  activePlan: PaymentPlan;
}

const initialState: PaymentPlanState = {
  plans: [],
  activePlan: null,
};

function setActivePlan(plans: PaymentPlan[]): PaymentPlan | undefined {
  const activePlan = plans.find(plan =>
    Boolean(
      plan.pors &&
        plan.pors.find(
          por =>
            (por.sts &&
              por.exts &&
              por.sts !== por.exts &&
              new Date(por.sts).valueOf() <= new Date().valueOf() &&
              new Date(por.exts).valueOf() > new Date().valueOf()) ||
            (!por.sts &&
              por.exts &&
              plan.seqno === -1 &&
              new Date(por.exts).valueOf() > new Date().valueOf())
        )
    )
  );

  if (activePlan) {
    const por = activePlan.pors.find(
      por =>
        por.sts &&
        por.exts &&
        por.sts !== por.exts &&
        new Date(por.sts).valueOf() <= new Date().valueOf() &&
        new Date(por.exts).valueOf() > new Date().valueOf()
    );

    const amount = por ? por.itms[0].qty : 1;

    return {
      ...activePlan,
      qty: amount,
    };
  }
  return undefined;
}

function mapPlans(plans: PaymentPlan[]): PaymentPlan[] {
  return plans
    .slice()
    .sort((a, b) => a.prLvl - b.prLvl)
    .map(plan => ({
      ...plan,
      qty: plan.pors && plan.pors[0] ? 2 : 1,
      infoDateStart:
        plan.pors &&
        plan.pors.find(
          por => new Date(por.sts).setHours(0).valueOf() > new Date().valueOf()
        )
          ? plan.pors.find(
              por =>
                new Date(por.sts).setHours(0).valueOf() > new Date().valueOf()
            ).sts
          : '',
      infoAmountStart:
        plan.pors &&
        plan.pors.find(
          por => new Date(por.sts).setHours(0).valueOf() > new Date().valueOf()
        )
          ? plan.pors.find(
              por =>
                new Date(por.sts).setHours(0).valueOf() > new Date().valueOf()
            ).itms[0].qty
          : null,
      pors: plan.pors || [],
    }));
}

export const paymentPlanReducer = createReducer(
  initialState,
  on(
    PaymentPlanActions.getPaymentPlansSuccess,
    PaymentPlanActions.updatePaymentPlanSuccess,
    (state, { payload }) => {
      const mappedPlans = mapPlans(payload);
      return {
        ...state,
        plans: mappedPlans,
        activePlan: setActivePlan(mappedPlans),
      };
    }
  )
);

export const PaymentPlanFeature = createFeature({
  name: 'paymentPlans',
  reducer: paymentPlanReducer,
});

export const { selectPlans, selectActivePlan } = PaymentPlanFeature;
