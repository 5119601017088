export type SnackBarTypes = 'ErrorMessage' | 'NormalMessage';

export interface SnackBarData {
  type: SnackBarTypes;
}

export interface ErrorSnackBarData extends SnackBarData {
  type: 'ErrorMessage';
  message: string;
  params?: string[];
}

export interface NormalSnackBarData extends SnackBarData {
  type: 'NormalMessage';
  message: string;
  params?: string[];
}

export function isErrorSnackbarData(
  data: SnackBarData
): data is ErrorSnackBarData {
  return data.type === 'ErrorMessage';
}

export function isNormalSnackbarData(
  data: SnackBarData
): data is NormalSnackBarData {
  return data.type === 'NormalMessage';
}
