import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Inject,
  Injectable,
  LOCALE_ID,
  Optional,
  SkipSelf,
} from '@angular/core';

import { MatKeyboardRef } from '../classes';
import { KeyboardContainerComponent } from '../components/keyboard-container/keyboard-container.component';
import { MAT_KEYBOARD_LAYOUTS } from '../configs/keyboard-layouts.config';
import { MatKeyboardConfig } from '../configs/keyboard.config';
import { KeyboardLayout, KeyboardLayouts, LocaleMap } from '../interfaces';
import {
  applyAvailableLayouts,
  applyConfigDefaults,
  getLayoutForLocale,
} from '../utils';
import { KeyboardComponent } from '../components/keyboard/keyboard.component';

@Injectable()
export class KeyboardService {
  private _keyboardRefAtThisLevel: MatKeyboardRef<KeyboardComponent> | null =
    null;

  private _availableLocales: LocaleMap = {};

  private get _openedKeyboardRef(): MatKeyboardRef<KeyboardComponent> | null {
    const parent = this._parentKeyboard;
    return parent ? parent._openedKeyboardRef : this._keyboardRefAtThisLevel;
  }

  private set _openedKeyboardRef(value: MatKeyboardRef<KeyboardComponent>) {
    if (this._parentKeyboard) {
      this._parentKeyboard._openedKeyboardRef = value;
    } else {
      this._keyboardRefAtThisLevel = value;
    }
  }

  get availableLocales(): LocaleMap {
    return this._availableLocales;
  }

  get isOpened(): boolean {
    return !!this._openedKeyboardRef;
  }

  constructor(
    private _overlay: Overlay,
    private _live: LiveAnnouncer,
    @Inject(LOCALE_ID) private _defaultLocale: string,
    @Inject(MAT_KEYBOARD_LAYOUTS) private _layouts: KeyboardLayouts,
    @Optional() @SkipSelf() private _parentKeyboard: KeyboardService
  ) {
    this._availableLocales = applyAvailableLayouts(_layouts);
  }

  openFromComponent(
    layoutOrLocale: string,
    config: MatKeyboardConfig
  ): MatKeyboardRef<KeyboardComponent> {
    const keyboardRef: MatKeyboardRef<KeyboardComponent> =
      this._attachKeyboardContent(config);

    keyboardRef.instance.darkTheme = config.darkTheme;
    keyboardRef.instance.isDebug = config.isDebug;

    if (this.availableLocales[layoutOrLocale]) {
      keyboardRef.instance.locale = layoutOrLocale;
      keyboardRef.instance.layout = getLayoutForLocale(
        layoutOrLocale,
        this._layouts
      );
    }

    if (this._layouts[layoutOrLocale]) {
      keyboardRef.instance.layout = this._layouts[layoutOrLocale];
      keyboardRef.instance.locale =
        this._layouts[layoutOrLocale].lang &&
        this._layouts[layoutOrLocale].lang.pop();
    }

    keyboardRef.afterDismissed().subscribe(() => {
      if (this._openedKeyboardRef === keyboardRef) {
        this._openedKeyboardRef = null;
      }
    });

    if (this._openedKeyboardRef) {
      this._openedKeyboardRef.afterDismissed().subscribe(() => {
        keyboardRef.containerInstance.enter();
      });
      this._openedKeyboardRef.dismiss();
    } else {
      keyboardRef.containerInstance.enter();
    }

    if (config.announcementMessage) {
      this._live.announce(config.announcementMessage, config.politeness);
    }

    this._openedKeyboardRef = keyboardRef;
    return this._openedKeyboardRef;
  }

  open(
    layoutOrLocale: string = this._defaultLocale,
    config: MatKeyboardConfig = {}
  ): MatKeyboardRef<KeyboardComponent> {
    const _config = applyConfigDefaults(config);

    return this.openFromComponent(layoutOrLocale, _config);
  }

  dismiss() {
    if (this._openedKeyboardRef) {
      this._openedKeyboardRef.dismiss();
    }
  }

  // mapLocale(locale: string = this._defaultLocale): string {
  //   let layout: string;
  //   const country = locale.split('-').shift();

  //   if (this.availableLocales[country]) {
  //     layout = this.availableLocales[locale];
  //   }

  //   if (this.availableLocales[locale]) {
  //     layout = this.availableLocales[locale];
  //   }

  //   if (!layout) {
  //     throw Error(`No layout found for locale ${locale}`);
  //   }

  //   return layout;
  // }

  // getLayoutForLocale(locale: string): KeyboardLayout {
  //   return this._layouts[this.mapLocale(locale)];
  // }

  private _attachKeyboardContainer(
    overlayRef: OverlayRef,
    config: MatKeyboardConfig
  ): KeyboardContainerComponent {
    const containerPortal = new ComponentPortal(
      KeyboardContainerComponent,
      config.viewContainerRef
    );
    const containerRef: ComponentRef<KeyboardContainerComponent> =
      overlayRef.attach(containerPortal);

    containerRef.instance.keyboardConfig = config;

    return containerRef.instance;
  }

  private _attachKeyboardContent(
    config: MatKeyboardConfig
  ): MatKeyboardRef<KeyboardComponent> {
    const overlayRef = this._createOverlay();
    const container = this._attachKeyboardContainer(overlayRef, config);
    const portal = new ComponentPortal(KeyboardComponent);
    const contentRef = container.attachComponentPortal(portal);
    return new MatKeyboardRef(
      contentRef.instance,
      container,
      overlayRef
    ) as MatKeyboardRef<KeyboardComponent>;
  }

  private _createOverlay(): OverlayRef {
    const state = new OverlayConfig({
      width: '100%',
    });

    state.positionStrategy = this._overlay
      .position()
      .global()
      .centerHorizontally()
      .bottom('0');

    return this._overlay.create(state);
  }
}
