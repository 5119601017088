import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ThemeManagementEffects } from './store/theme-management.effects';
import { ThemeManagementFeature } from './store/theme-management.reducers';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideState(ThemeManagementFeature),
    provideEffects(ThemeManagementEffects),
  ],
})
export class ThemeManagementModule {}
