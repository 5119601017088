<div fxLayout fxLayoutAlign="start center" fxLayoutGap="1rem">
  <mat-icon
    (click)="toggleKeyboard()"
    [matTooltip]="'GNR_SCREEN_KEYBOARD' | translate"
    [color]="hideText && keyboardSwitch.value ? 'accent' : ''"
    class="clickable">
    keyboard
  </mat-icon>
  <mat-slide-toggle *ngIf="!hideText" [formControl]="keyboardSwitch">{{
    'GNR_SCREEN_KEYBOARD' | translate
  }}</mat-slide-toggle>
</div>
