import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, CoreActions } from '@qtek/libs/store';
import { Observable } from 'rxjs';

@Injectable()
export class HideLoaderGuard {
  constructor(private store: Store<AppState>) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(CoreActions.closeLoading());
    return true;
  }
}
