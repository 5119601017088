import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Staff, EntityMeta, Person, Message } from '@qtek/shared/models';

export const RelationsActions = createActionGroup({
  source: 'Relationship',
  events: {
    'Get Staff': emptyProps(),
    'Get Staff Success': props<{ payload: Staff[]; meta: EntityMeta }>(),
    'Get Staff Failure': props<{ error: any }>(),
    'Create Employee': props<{ payload: any }>(),
    'Create Employee Success': props<{ payload: Staff; message: Message }>(),
    'Create Employee Failure': props<{ error: any }>(),
    'Delete Employee': props<{ payload: string }>(),
    'Delete Employee Success': props<{ payload: any; message: Message }>(),
    'Delete Employee Failure': props<{ error: any }>(),
    'Update Employee': props<{ payload: { form: Staff; usrId: string } }>(),
    'Update Employee Success': props<{ payload: Staff; message: Message }>(),
    'Update Employee Failure': props<{ error: any }>(),
    'Update Contact Details': props<{
      payload: { form: Person; relId: string; type: 'staff' | 'customer' };
    }>(),
    'Update Contact Details Success': props<{
      payload: { data: Person; type: 'staff' | 'customer' };
    }>(),
    'Update Contact Details Failure': props<{ error: any }>(),
    'Get Relations Demo Success': props<{ payload: any[] }>(),
    'Send Staff Invitation': props<{
      payload: { prsId: string; query: any };
      params?: Array<any>;
    }>(),
    'Send Staff Invitation Success': props<{
      payload: any;
      message: Message;
    }>(),
    'Send Staff Invitation Failure': props<{ error: any }>(),
  },
});
