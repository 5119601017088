<div class="mb-3" fxLayout fxLayoutAlign="space-between center">
  <span class="mat-headline-6 m-0">{{ data.title | translate }}</span>
  <button mat-icon-button matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
    <div>{{ data.information | translate }}</div>
  </div>
</mat-dialog-content>
