import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'qt-normal-snack-bar',
  templateUrl: './normal-snack-bar.component.html',
  styleUrls: ['./normal-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NormalSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { messageKey: string; params?: string[] }
  ) {}
}
