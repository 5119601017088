import {
  Action,
  ActionReducer,
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';
import {
  DocNode,
  PreUploadState,
  DOCUMENT_UPLOAD_CREATED,
  DocumentType,
} from '@qtek/shared/models';
import { DocumentActions } from './documents.actions';

export interface DocumentsState {
  companyLogo: string;
  companyLogoDoc: DocNode[];
  personalAvatar: string;
  entityDocuments: DocNode[];
  dataRoomEntityDocuments: DocNode[];
  invoiceAttachmentDocument: DocNode[];
  estimateAttachmentDocument: DocNode[];
  wcoAttachmentDocument: DocNode[];
  creatorLogos: string[];
  meetingDocuments: DocNode[];
  personalDocuments: DocNode[];
  bookingDocuments: DocNode[];
  menuDocuments: DocNode[];
  extrasDocuments: DocNode[];
  bookingQr: DocNode[];
  preUploadDocuments: PreUploadState[];
  isDataRoomEntityDocumentsLoading: boolean;
}

const initialState: DocumentsState = {
  companyLogo: '',
  companyLogoDoc: [],
  personalAvatar: '',
  entityDocuments: [],
  dataRoomEntityDocuments: [],
  invoiceAttachmentDocument: [],
  estimateAttachmentDocument: [],
  wcoAttachmentDocument: [],
  creatorLogos: [],
  meetingDocuments: [],
  personalDocuments: [],
  bookingDocuments: [],
  menuDocuments: [],
  extrasDocuments: [],
  bookingQr: [],
  preUploadDocuments: [],
  isDataRoomEntityDocumentsLoading: false,
};

export const documentsReducer: ActionReducer<DocumentsState, Action> =
  createReducer(
    initialState,
    on(DocumentActions.getLogosSuccess, (state, { payload }) => ({
      ...state,
      creatorLogos: payload || state.creatorLogos,
    })),
    on(DocumentActions.updateCompanyLogoSuccess, (state, { payload }) => ({
      ...state,
      companyLogo: payload && payload._id ? payload._id : state.companyLogo,
    })),
    on(DocumentActions.connectDocumentsList, state => ({
      ...state,
      isDataRoomEntityDocumentsLoading: true,
    })),
    on(DocumentActions.getEntityDocumentsWSSuccess, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: payload ? payload : [],
      isDataRoomEntityDocumentsLoading: false,
    })),
    on(DocumentActions.getEntityDocumentsFailure, state => ({
      ...state,
      isDataRoomEntityDocumentsLoading: false,
    })),
    on(DocumentActions.preUploadDocumentSuccess, (state, { payload }) => {
      const { tkn, docIds, refId } = payload.res;
      const { file } = payload;
      return {
        ...state,
        preUploadDocuments: state.preUploadDocuments.concat({
          tkn,
          docId: docIds[0],
          refId,
          status: DOCUMENT_UPLOAD_CREATED,
          filename: file.name,
          filesize: file.size,
        }),
      };
    }),
    on(
      DocumentActions.preUploadDocumentDeleteAllByIds,
      (state, { payload }) => ({
        ...state,
        preUploadDocuments: state.preUploadDocuments.filter(
          document => !payload.includes(document['docId'])
        ),
      })
    ),
    on(
      DocumentActions.preUploadDocumentDeleteAllByRef,
      (state, { payload }) => ({
        ...state,
        preUploadDocuments: state.preUploadDocuments.filter(
          document => document['refId'] != payload
        ),
      })
    ),
    on(DocumentActions.preUploadDocumentUpdate, (state, { payload }) => ({
      ...state,
      preUploadDocuments: state.preUploadDocuments.map(document =>
        document['tkn'] == payload.tkn
          ? { ...document, ...payload.toUpdate }
          : document
      ),
    })),
    on(DocumentActions.uploadDocumentsWSSuccess, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.concat({
        ...payload,
      }),
    })),
    on(DocumentActions.updateDocumentWSSuccess, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.map(document =>
        document['_id'] == payload._id ? { ...payload } : document
      ),
    })),
    on(DocumentActions.deleteDocumentWSSuccess, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.filter(
        (item: any) => item._id !== payload._id
      ),
      preUploadDocuments: state.preUploadDocuments.filter(
        item => item.docId !== payload._id
      ),
    })),
    on(DocumentActions.createFolderSuccessWS, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.concat(payload),
    })),
    on(DocumentActions.updateFolderSuccessWS, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.map(folder =>
        folder['_id'] == payload._id ? { ...payload } : folder
      ),
    })),
    on(DocumentActions.deleteFolderSuccessWS, (state, { payload }) => ({
      ...state,
      dataRoomEntityDocuments: state.dataRoomEntityDocuments.filter(
        (folder: any) => folder._id !== payload._id
      ),
    })),
    on(DocumentActions.disconnectDocumentsList, state => ({
      ...state,
      dataRoomEntityDocuments: [],
    })),
    on(DocumentActions.updatePersonalAvatarSuccess, (state, { payload }) => ({
      ...state,
      personalAvatar:
        payload && payload._id ? payload._id : state.personalAvatar,
    })),
    on(DocumentActions.deleteDocumentSuccess, (state, { payload }) => {
      const documents = state.entityDocuments.filter(
        document => document._id !== payload.docId
      );
      switch (payload.attr) {
        case DocumentType.COMPANY_LOGO:
          return {
            ...state,
            companyLogo: '',
            companyLogoDoc: state.companyLogoDoc.filter(
              document => document._id !== payload.docId
            ),
          };
        case DocumentType.INVOICE_ATTACHMENT_DOCUMENT:
          return {
            ...state,
            invoiceAttachmentDocument: state.invoiceAttachmentDocument.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT:
          return {
            ...state,
            estimateAttachmentDocument: state.estimateAttachmentDocument.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.WCO_ATTACHMENT_DOCUMENT:
          return {
            ...state,
            wcoAttachmentDocument: state.wcoAttachmentDocument.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.PERSONAL_AVATAR:
          return {
            ...state,
            personalAvatar: '',
          };
        case DocumentType.MEETING_ATTACHMENT_DOCUMENT:
          return {
            ...state,
            meetingDocuments: state.meetingDocuments.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.PERSONAL_DOCUMENTS:
          return {
            ...state,
            personalDocuments: state.personalDocuments.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.COMPANY_BOOKING_BACKGROUND:
          return {
            ...state,
            bookingDocuments: state.bookingDocuments.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT:
          return {
            ...state,
            menuDocuments: state.menuDocuments.filter(
              document => document._id !== payload.docId
            ),
            entityDocuments: documents,
          };
        case DocumentType.SERVICE_IMAGE:
          return {
            ...state,
            entityDocuments: documents,
          };
        case DocumentType.SERVICE_EXTRA_IMAGE:
          return {
            ...state,
            entityDocuments: documents,
            extrasDocuments: state.extrasDocuments.filter(
              document => document._id !== payload.docId
            ),
          };
        case DocumentType.ITEM_DOCUMENT:
          return {
            ...state,
            entityDocuments: state.entityDocuments.filter(
              document => document._id !== payload.docId
            ),
          };
        default:
          return state;
      }
    }),
    on(DocumentActions.uploadDocumentsSuccess, (state, { payload }) => {
      if (Array.isArray(payload)) {
        switch (payload[0].attr) {
          case DocumentType.INVOICE_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              invoiceAttachmentDocument:
                state.invoiceAttachmentDocument.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              estimateAttachmentDocument:
                state.estimateAttachmentDocument.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          case DocumentType.WCO_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              wcoAttachmentDocument:
                state.wcoAttachmentDocument.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          case DocumentType.COMPANY_LOGO:
            return {
              ...state,
              companyLogo: payload[0]._id,
              companyLogoDoc: payload,
            };
          case DocumentType.PERSONAL_AVATAR:
            return {
              ...state,
              personalAvatar: payload[0]._id,
            };
          case DocumentType.MEETING_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              meetingDocuments: state.meetingDocuments.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.PERSONAL_DOCUMENTS: {
            return {
              ...state,
              personalDocuments: state.personalDocuments.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.COMPANY_BOOKING_BACKGROUND: {
            return {
              ...state,
              bookingDocuments: state.bookingDocuments.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT: {
            return {
              ...state,
              menuDocuments: state.menuDocuments.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.SERVICE_IMAGE: {
            return {
              ...state,
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.SERVICE_EXTRA_IMAGE: {
            return {
              ...state,
              extrasDocuments: state.extrasDocuments.concat(payload),
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }

          case DocumentType.ITEM_DOCUMENT: {
            return {
              ...state,
              entityDocuments: state.entityDocuments.concat(payload),
            };
          }
          default: {
            return state;
          }
        }
      } else {
        return {
          ...state,
        };
      }
    }),
    on(DocumentActions.getEntityDocumentsSuccess, (state, { payload }) => {
      if (Array.isArray(payload)) {
        switch (payload[0].attr) {
          case DocumentType.COMPANY_LOGO:
            return {
              ...state,
              companyLogo: payload[0]._id,
              companyLogoDoc: payload,
            };
          case DocumentType.PERSONAL_AVATAR:
            return {
              ...state,
              personalAvatar: payload[0]._id,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.INVOICE_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              invoiceAttachmentDocument: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.ESTIMATE_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              estimateAttachmentDocument: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.WCO_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              wcoAttachmentDocument: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.MEETING_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              meetingDocuments: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.PERSONAL_DOCUMENTS:
            return {
              ...state,
              personalDocuments: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.COMPANY_BOOKING_BACKGROUND:
            return {
              ...state,
              bookingDocuments: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.COMPANY_BOOKING_QR:
            return {
              ...state,
              bookingQr: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.FOOD_MENU_ATTACHMENT_DOCUMENT:
            return {
              ...state,
              menuDocuments: payload,
              entityDocuments: payload ? payload : [],
            };
          case DocumentType.SERVICE_EXTRA_IMAGE:
            return {
              ...state,
              extrasDocuments: payload,
              entityDocuments: payload ? payload : [],
            };
          default:
            return { ...state, entityDocuments: payload ? payload : [] };
        }
      } else {
        return state;
      }
    })
  );

export const DocumentsFeature = createFeature({
  name: 'documents',
  reducer: documentsReducer,
});

export const {
  selectCompanyLogo,
  selectPersonalAvatar,
  selectCompanyLogoDoc,
  selectCreatorLogos,
  selectEntityDocuments,
  selectDataRoomEntityDocuments,
  selectIsDataRoomEntityDocumentsLoading,
  selectPersonalDocuments,
  selectBookingDocuments,
  selectBookingQr,
  selectMenuDocuments,
  selectInvoiceAttachmentDocument,
  selectExtrasDocuments,
  selectPreUploadDocuments,
} = DocumentsFeature;
