import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AppState } from '../index';
import {
  entityName,
  AccountOutput,
  BackendResponse,
} from '@qtek/shared/models';

import { HttpEntityManager } from './../http-entity-manager';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends HttpEntityManager<AccountOutput, any> {
  entityName: entityName = 'acn';

  constructor(
    protected http: HttpClient,
    private store: Store<AppState>
  ) {
    super();
  }

  /**
   * Get list of user's cmps
   *
   */
  getCompanies() {
    return this.http.get<BackendResponse>('/api/v1/e/relcmps', {
      withCredentials: true,
    });
  }

  /**
   * Remove company
   *
   * @param id
   */
  removeCompany(id: string, force?: boolean) {
    let params = new HttpParams();

    if (force) {
      params = params.append('force', 'true');
    }

    return this.http.delete<BackendResponse>(`/api/v1/e/relcmp/${id}`, {
      withCredentials: true,
      params,
    });
  }

  updateCompany(
    additionalPayload: any = {},
    payload: any = {},
    companyId?: string
  ) {
    return super.put(payload, null, additionalPayload);
  }
}
