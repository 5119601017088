import { NgIf } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { KEYBOARD_STORAGE_KEY } from '@qtek/libs/keyboard';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'qt-keyboard-switch',
  templateUrl: './keyboard-switch.component.html',
  imports: [
    MatIconModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatSlideToggleModule,
    TranslationCoreModule,
    ReactiveFormsModule,
    NgIf,
  ],
})
export class KeyboardSwitchComponent implements OnInit, OnDestroy {
  @Input() @HostBinding('style.color') color: string;
  @Input() hideText: boolean;

  keyboardSwitch: UntypedFormControl = new UntypedFormControl(false);
  destroy$ = new Subject<void>();

  ngOnInit() {
    if (localStorage.getItem(KEYBOARD_STORAGE_KEY)) {
      this.keyboardSwitch.setValue(true);
    }
    this.keyboardSwitch.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          localStorage.setItem(KEYBOARD_STORAGE_KEY, 'true');
        } else {
          localStorage.removeItem(KEYBOARD_STORAGE_KEY);
        }
      });
  }

  toggleKeyboard(): void {
    if (this.hideText) {
      this.keyboardSwitch.setValue(!this.keyboardSwitch.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
