import { createActionGroup, emptyProps } from '@ngrx/store';

export const CoreActions = createActionGroup({
  source: 'Core',
  events: {
    'Open Loading': emptyProps(),
    'Close Loading': emptyProps(),
    'Start Loading Animation': emptyProps(),
    'Stop Loading Animation': emptyProps(),
  },
});
