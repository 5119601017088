import { Pipe, PipeTransform } from '@angular/core';
import { Icon } from './icon';

@Pipe({
  name: 'convertToRows',
  standalone: false,
})
export class ConvertToRowsPipe implements PipeTransform {
  transform(value: Icon[], numOfCols: number): Icon[][] {
    if (!numOfCols || numOfCols === 1) {
      return value.map(val => [val]);
    }

    const cols: Icon[][] = [];
    let column: Icon[] = [];
    return value.reduce((prevValue, currentIcon) => {
      column.push(currentIcon);

      if (column.length < numOfCols - 1) {
        return cols;
      }

      cols.push(column);
      column = [];
      return cols;
    }, cols);
  }
}
