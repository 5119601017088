import { createReducer, on, createFeature, createSelector } from '@ngrx/store';
import { AccountOutput, PersonOutput } from '@qtek/shared/models';
import { UserActions } from './user.actions';

interface UserActionParams {
  sts: number;
  vndId: string;
}
interface UserAction {
  params: UserActionParams;
}

export interface UserState {
  prs: PersonOutput;
  acn: AccountOutput | null;
  rel: any | null;
  cmps: any[] | null;
  actions: UserAction[] | null;
}

const initialState: UserState = {
  prs: null,
  cmps: [],
  acn: null,
  rel: null,
  actions: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.getCurrentUserSuccess, (state, { payload }) =>
    payload == null ? initialState : { ...state, ...payload }
  ),
  on(UserActions.updateCurrentUserSuccess, (state, { payload }) => ({
    ...state,
    prs: payload || state.prs,
    actions: payload ? payload.actions : state.actions,
  })),
  on(UserActions.updateCurrentUserCompanySuccess, (state, { payload }) => {
    let index, newCmps;

    if (payload == null) {
      return { ...state };
    }

    if (payload.name) {
      const name = payload.name.name;
      index = state.cmps.findIndex(cmp => cmp.cmpId === payload.cmpId);
      if (index !== -1) {
        newCmps = state.cmps.slice();
        newCmps[index] = {
          ...state.cmps[index],
          acn: {
            ...state.cmps[index].acn,
            name: {
              ...state.cmps[index].acn.name,
              name,
            },
          },
        };
      }
    }

    return {
      ...state,
      acn: payload ? payload : state.acn,
      cmps: index >= 0 ? newCmps : state.cmps,
      actions: payload ? payload.actions : state.actions,
    };
  }),
  on(UserActions.getCurrentUserCompaniesSuccess, (state, { payload }) => ({
    ...state,
    cmps: payload || [],
  })),
  on(UserActions.deleteCurrentUserCompanySuccess, (state, { payload }) => ({
    ...state,
    cmps: state.cmps.filter(cmp => cmp.relId !== payload),
  }))
);

export const UserFeature = createFeature({
  name: 'user',
  reducer: userReducer,
  extraSelectors: ({ selectUserState }) => ({
    isAuthorized: createSelector(
      selectUserState,
      state => state.prs && Boolean(state.prs.prsId)
    ),
    selectCurrentPerson: createSelector(selectUserState, state => state.prs),
    selectMainCompany: createSelector(selectUserState, state => state.acn),
    selectPersonProgress: createSelector(selectUserState, state =>
      state.prs && state.prs.bitMsks && state.prs.bitMsks._prgs
        ? state.prs.bitMsks._prgs
        : 0
    ),
    selectCompanyProgress: createSelector(selectUserState, state =>
      state.acn && state.acn.bitMsks && state.acn.bitMsks._prgs
        ? state.acn.bitMsks._prgs
        : 0
    ),
    selectDemoDataProgress: createSelector(selectUserState, state =>
      state.acn && state.acn.bitMsks && state.acn.bitMsks._ddb
        ? state.acn.bitMsks._ddb
        : 0
    ),
    selectTutorialProgress: createSelector(selectUserState, state =>
      state.prs && state.prs.bitMsks && state.prs.bitMsks._uitut
        ? state.prs.bitMsks._uitut
        : undefined
    ),
    selectCurrentPersonLanguage: createSelector(
      selectUserState,
      state => state.prs?.lng
    ),
    selectRelationType: createSelector(selectUserState, state =>
      state.rel ? state.rel.rl : null
    ),
    selectUserRelation: createSelector(selectUserState, state => state.rel),
    selectUserCompanies: createSelector(selectUserState, state => state.cmps),
    selectIsPersonalAccount: createSelector(
      selectUserState,
      state => state.acn && (state.acn.tp === 2000 || !state.acn.tp)
    ),
    selectUserActions: createSelector(selectUserState, state => state.actions),
    selectCurrentPersonTz: createSelector(
      selectUserState,
      state => state.prs?.tz
    ),
  }),
});

export const {
  isAuthorized,
  selectCurrentPerson,
  selectMainCompany,
  selectPersonProgress,
  selectCompanyProgress,
  selectDemoDataProgress,
  selectTutorialProgress,
  selectCurrentPersonLanguage,
  selectRelationType,
  selectUserRelation,
  selectUserCompanies,
  selectIsPersonalAccount,
  selectUserActions,
  selectCurrentPersonTz,
} = UserFeature;
