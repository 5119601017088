import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  NgZone,
  Renderer2,
  Version,
  ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  APP_ENVIRONMENT,
  APP_VERSION,
  ApiCoreService,
} from '@qtek/core/api-core';
import { LocalStorageManagementService } from '@qtek/core/local-storage-management';
import { ThemeManagementService } from '@qtek/core/theme-management';
import { WebSocketService } from '@qtek/core/websockets-core';
import {
  APP_COMPONENT_DEFAULT_STYLES,
  APP_COMPONENT_DEFAULT_TEMPLATE,
  QtAppComponent,
  QtIntl,
} from '@qtek/libs/app-component';
import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { PermissionsService } from '@qtek/libs/permissions';
import { SnackBarService } from '@qtek/libs/snack-bar-core';
import {
  AppState,
  CalendarActions,
  CoreActions,
  NewRelationsActions,
} from '@qtek/libs/store';
import { TranslationCoreService } from '@qtek/libs/translation-core';
import {
  DeliveryStatusType,
  DeliveryType,
  EVENT_DELIVERY_STATUS,
  Environment,
  INVITATION_DELIVERY_STATUS,
  Message,
} from '@qtek/shared/models';
import { BreadcrumbService } from '@qtek/shared/services';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'qt-lfe-root',
  template: APP_COMPONENT_DEFAULT_TEMPLATE,
  styles: [APP_COMPONENT_DEFAULT_STYLES],
  standalone: false,
})
export class AppComponent extends QtAppComponent {
  constructor(
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    protected matIconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected actions$: Actions,
    protected store: Store<AppState>,
    protected dialogLoader: LazyDialogLoader,
    protected snackBar: MatSnackBar,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(QtIntl) protected intls: QtIntl[],
    @Inject(APP_ENVIRONMENT) environment: Environment,
    @Inject(APP_VERSION) version: Version,
    private update: SwUpdate,
    protected route: ActivatedRoute,
    protected viewContainerRef: ViewContainerRef,
    protected translateService: TranslationCoreService,
    protected overlay: OverlayContainer,
    protected apiCoreService: ApiCoreService,
    protected webSocketService: WebSocketService,
    protected localStorageManagementService: LocalStorageManagementService,
    protected themeManagementService: ThemeManagementService,
    protected snackBarService: SnackBarService,
    protected permissionsService: PermissionsService,
    protected breadCrumbs: BreadcrumbService
  ) {
    super(
      ngZone,
      renderer,
      matIconRegistry,
      sanitizer,
      router,
      actions$,
      store,
      snackBar,
      document,
      intls,
      route,
      viewContainerRef,
      translateService,
      overlay,
      apiCoreService,
      webSocketService,
      localStorageManagementService,
      themeManagementService,
      snackBarService,
      environment,
      version
    );
    this.update.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() => {
        this.update.activateUpdate().then(() => document.location.reload());
      });

    // Enables loading on app boot
    this.store.dispatch(CoreActions.openLoading());
  }

  onInitCallback() {
    this.route.queryParams.subscribe(params => {
      const { err } = params;

      if (err) {
        const errorCode = atob(err);

        this.dialogLoader
          .open('alert', { message: new Message(errorCode) })
          .subscribe();

        // Remove query param
        this.router.navigate([], {
          queryParams: {
            ...params,
            err: null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    });

    this.actions$
      .pipe(
        ofType(NewRelationsActions.sendInvitationsSuccess),
        switchMap((payload: any) =>
          this.dialogLoader.open('delivery-dialog', {
            ...INVITATION_DELIVERY_STATUS[payload.sts as DeliveryStatusType],
            endpoints: payload.endpoints || [],
            deliveryType: DeliveryType.INVITATION_DELIVERY,
          })
        )
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(CalendarActions.sendEventSuccess),
        switchMap(({ payload }) =>
          this.dialogLoader.open('delivery-dialog', {
            ...EVENT_DELIVERY_STATUS[payload.sts as DeliveryStatusType],
            endpoints: payload.endpoints || [],
            deliveryType: DeliveryType.EVENT_DELIVERY,
          })
        )
      )
      .subscribe();

    this.route.queryParams.subscribe(params => {
      if (params['p'] === 'botreg') {
        this.dialogLoader
          .open('alert', {
            title: 'MSG_BOT_REG_CNF_TITLE',
            message: new Message('MSG_BOT_REG_CNF_TXT'),
            closeButton: 'Close',
          })
          .subscribe();
      }
    });
  }
}
