import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Store } from '@ngrx/store';
import {
  ThemeManagementActions,
  ThemeManagementFeature,
} from '@qtek/core/theme-management';
import { AppState } from '@qtek/libs/store';
import { TranslationCoreModule } from '@qtek/libs/translation-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'qt-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    TranslationCoreModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    AsyncPipe,
  ],
})
export class ThemeSwitchComponent {
  isDarkTheme$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.isDarkTheme$ = this.store
      .select(ThemeManagementFeature.selectUiTheme)
      .pipe(map(theme => theme === 'dark'));
  }

  changeTheme(event: MatSlideToggleChange): void {
    this.store.dispatch(
      ThemeManagementActions.setTheme({
        theme: event.checked ? 'dark' : 'light',
      })
    );
  }
}
