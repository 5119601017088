import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, MetaActions, selectCountryCode } from '@qtek/libs/store';
import { conformToMask, getPhoneMask } from '@qtek/shared/utils';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

let isCountryCodesLoaded = false;

@Pipe({
  name: 'phone',
  pure: false,
  standalone: true,
})
export class PhonePipe implements PipeTransform, OnDestroy {
  private destroy$ = new Subject<void>();

  private tel$ = new BehaviorSubject<string | undefined>(undefined);
  private country$ = new BehaviorSubject<string>('');
  private includeCode$ = new BehaviorSubject<boolean>(false);

  private value: string;

  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {
    const phones$ = this.store.pipe(
      select(selectCountryCode),
      tap(value => {
        if (!value && !isCountryCodesLoaded) {
          isCountryCodesLoaded = true;
          this.store.dispatch(MetaActions.loadMetaCountryCode());
        }
      }),
      filter(value => value?.length > 0),
      first()
    );

    combineLatest([phones$, this.tel$, this.country$, this.includeCode$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([phones]) => Boolean(phones))
      )
      .subscribe(([phones]) => {
        this.value = this.formatPhoneNumber(phones);
        this.cdr.markForCheck();
      });
  }

  transform(tel: string, country: string, includeCode = false): string {
    this.tel$.next(tel);
    this.country$.next(country);
    this.includeCode$.next(includeCode);
    return this.value || tel;
  }

  private formatPhoneNumber(phones: any[]): string {
    const tel = this.tel$.getValue();
    const country = this.country$.getValue();
    const includeCode = this.includeCode$.getValue();

    if (!tel) {
      return '';
    }

    const phoneCountry = phones.find(phone => phone.id === country);
    const value = tel.toString().trim().replace(/^\+/, '');

    if (phoneCountry) {
      return `${includeCode ? '+' + phoneCountry.cd + ' ' : ''}${
        conformToMask(value, getPhoneMask(phoneCountry.mask).mask, {
          guide: false,
        }).conformedValue
      }`;
    }

    const formattedTel = '+1' + tel;
    const countryCodeStr = formattedTel.slice(0, 2);
    const areaCodeStr = formattedTel.slice(2, 5);
    const midSectionStr = formattedTel.slice(5, 8);
    const lastSectionStr = formattedTel.slice(8);

    return `${countryCodeStr} (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
