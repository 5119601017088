import { Inject, InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromMeta from './meta/meta.reducer';
import * as fromProductSchemas from './product-schemes/product-schemas.reducer';

export * from './meta-reducers';

export interface AppState {
  meta: fromMeta.MetaState;
  productSchemas: fromProductSchemas.SchemasState;
}

export type BookingAppState = Pick<AppState, 'meta' | 'productSchemas'>;

/**
 * Combine default and application specyfic reducers. Use {@link APP_REDUCERS} token
 * to add reducers.
 */
export function getReducers(appReducers: Partial<ActionReducerMap<AppState>>) {
  return {
    ...appReducers,
  };
}
/**
 * Application specific reducers.
 */
export const APP_REDUCERS = new InjectionToken<
  ActionReducerMap<Partial<AppState>>
>('APP_REDUCERS');

export const REDUCERS = new InjectionToken<ActionReducerMap<Partial<AppState>>>(
  'REDUCERS'
);

/**
 * Sets up base store providers.
 */
export const STORE_PROVIDERS = [
  {
    provide: APP_REDUCERS,
    useValue: {},
  },
  {
    provide: REDUCERS,
    useFactory: getReducers,
    deps: [[new Inject(APP_REDUCERS)]],
  },
];
