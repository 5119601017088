export function loadLazyStyles(envAppName: string) {
  const userAgent = navigator.userAgent.toLowerCase();
  const isFirefox = userAgent.includes('firefox');

  if (envAppName !== 'booking') {
    addStylesLink(isFirefox, '/lazy-loaded-style.css');
  }
  addStylesLink(
    isFirefox,
    'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined'
  );
  addStylesLink(
    isFirefox,
    'https://fonts.googleapis.com/css?family=Roboto:300,400,500&subset=latin,latin-ext'
  );
}

function addStylesLink(isFirefox: boolean, address: string) {
  const link = document.createElement('link');
  const head = document.querySelector('head');

  if (!isFirefox) {
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'style');
    link.onload = function () {
      link.onload = null;
      link.setAttribute('rel', 'stylesheet');
    };
  } else {
    link.setAttribute('rel', 'stylesheet');
  }

  link.setAttribute('href', address);

  head.appendChild(link);
}
