import {
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
  HttpSentEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  DocNode,
  PreUploadState,
  DOCUMENT_UPLOAD_CANCELED,
  DOCUMENT_UPLOAD_STARTED,
  DOCUMENT_UPLOAD_COMPLETED,
} from '@qtek/shared/models';
import {
  switchMap,
  takeUntil,
  filter,
  map,
  tap,
  mergeMap,
  catchError,
  of,
  withLatestFrom,
} from 'rxjs';
import { AppState } from '..';
import { DocumentActions } from './documents.actions';
import { DocumentsService } from './documents.service';
import { selectPreUploadDocuments } from './documents.reducer';
import { selectCurrentPerson } from '../user';

@Injectable()
export class DocumentsEffects {
  connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.connectDocumentsList),
      switchMap(({ payload }: any) =>
        this.documentsService.subscribe({ prms: payload }).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(DocumentActions.disconnectDocumentsList),
              filter((action: any) => payload?.mysid === action.payload?.mysid)
            )
          )
        )
      ),
      map(({ op, res, sts }: any) => {
        switch (op) {
          case 'query':
            return res
              ? DocumentActions.getEntityDocumentsWSSuccess({ payload: res })
              : DocumentActions.getEntityDocumentsFailure();
          case 'ins':
            return res
              ? DocumentActions.uploadDocumentsWSSuccess({ payload: res })
              : DocumentActions.uploadDocumentsWSFailure({ payload: sts });
          case 'del':
            return res
              ? DocumentActions.deleteDocumentWSSuccess({ payload: res })
              : DocumentActions.deleteDocumentWSFailure({ payload: sts });
          case 'upd':
            return res
              ? DocumentActions.updateDocumentWSSuccess({ payload: res })
              : DocumentActions.updateDocumentWSFailure({ payload: sts });
          default:
            return { type: 'NONE' };
        }
      })
    )
  );

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.updateDocumentWS),
        tap(({ payload }) => this.documentsService.updateDocumentWS(payload))
      ),
    { dispatch: false }
  );

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.deleteDocumentWS),
        tap(({ payload }) => this.documentsService.deleteDocumentWS(payload))
      ),
    { dispatch: false }
  );

  uploadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.uploadDocuments),
      mergeMap(({ payload }) =>
        this.documentsService.uploadDocuments(payload.data, payload.attr).pipe(
          filter((event: any) => event.type === HttpEventType.Response),
          map((event: HttpResponse<DocNode>) => {
            return DocumentActions.uploadDocumentsSuccess({
              payload: [
                {
                  ...event.body,
                  attr: payload.attr,
                },
              ],
            });
          }),
          catchError(({ sts }) =>
            of(DocumentActions.uploadDocumentsFailure({ payload: sts }))
          )
        )
      )
    )
  );

  preUploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.preUploadDocument),
      mergeMap(({ payload }) => {
        const { data, file } = payload;
        return this.documentsService.preUploadDocument(data).pipe(
          map(({ res }) => {
            return DocumentActions.preUploadDocumentSuccess({
              payload: { res, file },
            });
          }),
          catchError(({ sts }) =>
            of(DocumentActions.preUploadDocumentFailure({ payload: sts }))
          )
        );
      })
    )
  );

  preUploadDocument2Stage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.preUploadDocumentSuccess),
        mergeMap(({ payload }) => {
          const { res, file } = payload;

          const formData: FormData = new FormData();

          formData.append('data', file);
          formData.append('tkn', res.tkn);

          let timestamp = Date.now();
          let lastLoaded = 0;

          return this.documentsService.uploadDocuments(formData).pipe(
            takeUntil(
              this.store.select(selectPreUploadDocuments).pipe(
                map(docs => docs.find(doc => doc.tkn === res?.tkn)),
                filter(
                  (doc: PreUploadState) =>
                    doc.status === DOCUMENT_UPLOAD_CANCELED
                )
              )
            ),
            filter(
              (event: any) =>
                event.type === HttpEventType.Response ||
                event.type === HttpEventType.UploadProgress ||
                event.type === HttpEventType.Sent
            ),
            map(
              (
                event: HttpResponse<DocNode> | HttpProgressEvent | HttpSentEvent
              ) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    this.store.dispatch(
                      DocumentActions.preUploadDocumentUpdate({
                        payload: {
                          tkn: res.tkn,
                          toUpdate: {
                            status: DOCUMENT_UPLOAD_STARTED,
                          },
                        },
                      })
                    );
                    return of(null);
                  case HttpEventType.UploadProgress: {
                    const loaded = event.loaded;
                    const time = Date.now() - timestamp;
                    const percent = (loaded - lastLoaded) / 100;
                    const chunk = percent * file.size;
                    const speed = (chunk / (time / 1000)).toFixed(2);

                    timestamp = Date.now();
                    lastLoaded = loaded;

                    this.store.dispatch(
                      DocumentActions.preUploadDocumentUpdate({
                        payload: {
                          tkn: res.tkn,
                          toUpdate: {
                            progress: Math.round(
                              (100 * event.loaded) / file.size
                            ),
                            speed: parseFloat(speed),
                          },
                        },
                      })
                    );
                    return of(null);
                  }
                  case HttpEventType.Response:
                    this.store.dispatch(
                      DocumentActions.preUploadDocumentUpdate({
                        payload: {
                          tkn: res.tkn,
                          toUpdate: {
                            status: DOCUMENT_UPLOAD_COMPLETED,
                          },
                        },
                      })
                    );
                    this.store.dispatch(
                      DocumentActions.updateDocumentWSSuccess({
                        payload: event.body,
                      })
                    );
                    return of(null);

                  default:
                    return of(null);
                }
              }
            )
            // catchError(({ sts }) => of(DocumentActions.uploadDocumentsFailure({ payload: sts })))
          );
          // Number of concurrent uploads
        }, 5)
      ),
    { dispatch: false }
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.deleteDocument),
      mergeMap(({ payload }) =>
        this.documentsService.deleteDocument(payload.docId, payload.refId).pipe(
          map(() => DocumentActions.deleteDocumentSuccess({ payload })),
          catchError(({ sts }) =>
            of(DocumentActions.deleteDocumentFailure({ payload: sts }))
          )
        )
      )
    )
  );

  getEntityDocumentsWs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.getEntityDocumentsWS),
        tap(({ payload }) => this.documentsService.getItemDocumentsWs(payload))
      ),
    { dispatch: false }
  );

  getEntityDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.getEntityDocuments),
      withLatestFrom(this.store.select(selectCurrentPerson)),
      mergeMap(([action, person]) => {
        const refId = action.payload?.refId || `prs=${person.prsId}`;

        return this.documentsService
          .getDocumentsOfEntity(refId, action.payload?.type)
          .pipe(
            map(({ res }) =>
              DocumentActions.getEntityDocumentsSuccess({ payload: res })
            ),
            catchError(() => of(DocumentActions.getEntityDocumentsFailure()))
          );
      })
    )
  );

  getCreatorLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.getLogos),
      mergeMap(() =>
        this.documentsService.getLogoTemplates().pipe(
          map(({ res }) => DocumentActions.getLogosSuccess({ payload: res })),
          catchError(() => of(DocumentActions.getEntityDocumentsFailure()))
        )
      )
    )
  );

  updateLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.updateCompanyLogo),
      mergeMap(({ payload }) =>
        this.documentsService.updateLogo(payload).pipe(
          map(({ res }) =>
            DocumentActions.updateCompanyLogoSuccess({ payload: res })
          ),
          catchError(({ sts }) =>
            of(DocumentActions.updateCompanyLogoFailure({ payload: sts }))
          )
        )
      )
    )
  );

  sendDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.sendDocument),
      mergeMap(({ payload }) =>
        this.documentsService
          .sendDocument(payload.id, payload.destination)
          .pipe(
            map(({ res }) =>
              DocumentActions.sendDocumentSuccess({
                payload: { response: res, id: payload.id },
              })
            ),
            catchError(({ sts }) =>
              of(DocumentActions.sendDocumentFailure({ payload: sts }))
            )
          )
      )
    )
  );

  updateDocDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.updateDocumentDescription),
      mergeMap(({ payload }) =>
        this.documentsService
          .updateDocumentDescription(payload.id, payload.description)
          .pipe(
            map(({ res }) =>
              DocumentActions.updateDocumentDescriptionSuccess({
                payload: { description: res, id: payload.id },
              })
            ),
            catchError(({ sts }) =>
              of(
                DocumentActions.updateDocumentDescriptionFailure({
                  payload: sts,
                })
              )
            )
          )
      )
    )
  );

  bulkDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.bulkDelete),
      mergeMap(({ payload }) =>
        this.documentsService.bulkDelete(payload).pipe(
          map(response => {
            return DocumentActions.bulkDeleteSuccess({
              payload: ((response as any).res as any[]).map(item => item._id),
            });
          }),
          catchError(error =>
            of(DocumentActions.bulkDeleteFailure({ payload: error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private documentsService: DocumentsService,
    private store: Store<AppState>
  ) {}
}
