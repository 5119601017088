import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverArrowDirective, PopoverCloseDirective } from './directives';
import { PopoverComponent } from './components/popover/popover.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule],
  declarations: [
    PopoverArrowDirective,
    PopoverCloseDirective,
    PopoverComponent,
  ],
  providers: [],
  exports: [PopoverArrowDirective, PopoverCloseDirective],
})
export class PopoverModule {}
