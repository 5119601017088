import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ThemeManagementFeature } from '@qtek/core/theme-management';
import {
  AppState,
  selectAnimationLoading,
  selectDisplayLoading,
} from '@qtek/libs/store';
import { EMPTY, Observable, timer } from 'rxjs';
import { debounce, debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'qt-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe],
  template: `
    <div @fadeInOut class="container" *ngIf="display | async">
      <img
        id="synply-loader"
        class="synply-loader"
        src="/assets/img/logo_anim.svg"
        height="150"
        width="150" />
    </div>
  `,
  styles: [
    `
      .container {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--app-main-background);
        z-index: 10;
      }
    `,
  ],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoaderComponent {
  display: Observable<boolean> = this.store
    .select(selectDisplayLoading)
    .pipe(debounceTime(0));
  animation: Observable<boolean> = this.store
    .select(selectAnimationLoading)
    .pipe(debounce(val => (!val ? timer(500) : EMPTY)));
  constructor(
    private store: Store<AppState>,
    private element: ElementRef
  ) {
    this.store
      .select(ThemeManagementFeature.selectUiTheme)
      .pipe(map(theme => theme === 'dark'))
      .subscribe(isDarkTheme => {
        isDarkTheme
          ? this.element.nativeElement.classList.add('darkTheme')
          : this.element.nativeElement.classList.remove('darkTheme');
      });
  }
}
