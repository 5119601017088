import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Customer, EntityMeta } from '@qtek/shared/models';

export const NewRelationsActions = createActionGroup({
  source: 'Relations',
  events: {
    'Subscribe People': props<{ mysid: string; params?: any; view?: string }>(),
    'Unsubscribe People': props<{ mysid: string }>(),
    'Subscribe Institutions': props<{ mysid: string }>(),
    'Unsubscribe Institutions': props<{ mysid: string }>(),
    'Update Institution Logo': props<{ formData: FormData; relId: string }>(),
    'Update Institution Logo Success': emptyProps(),
    'Update Institution Logo Failure': props<{ error: Error }>(),
    'Get People Success': props<{ customers: Customer[]; meta: EntityMeta }>(),
    'Get People Failure': props<Error>(),
    'Get Institutions Success': props<{
      customers: Customer[];
      meta: EntityMeta;
    }>(),
    'Get Institutions Failure': props<Error>(),
    'Create People': props<{ customer: Customer }>(),
    'Create People Success': props<{ customer: Customer }>(),
    'Create People Error': props<{ error: Error }>(),
    'Update People': props<{ customer: Partial<Customer> }>(),
    'Update People Success': props<{ customer: Customer }>(),
    'Update People Error': props<{ error: Error }>(),
    'Delete People': props<{ relId: string; tp?: string }>(),
    'Delete People Success': props<{ customer: Customer }>(),
    'Delete People Error': props<{ error: Error }>(),
    'Create Institutions': props<{ customer: Customer }>(),
    'Create Institutions Success': props<{ customer: Customer }>(),
    'Create Institutions Error': props<{ error: Error }>(),
    'Update Institutions': props<{
      customer: Partial<Customer>;
      mysid: string;
    }>(),
    'Update Institutions Success': props<{ customer: Customer }>(),
    'Update Institutions Error': props<{ error: Error }>(),
    'Delete Institution': props<{ relId: string; tp: string }>(),
    'Delete Institution Success': props<{ customer: Customer }>(),
    'Delete Institution Error': props<{ error: Error }>(),
    'Send Invitations': props<{ relId: string; query: any }>(),
    'Send Invitations Success': props<{ sts: any }>(),
    'Send Invitations Error': props<{ error: Error }>(),

    'Subscribe Customer By Id': props<{
      mysid: string;
      usrId: string;
    }>(),
    'Unsubscribe Customer By Id': props<{
      mysid: string;
    }>(),

    'Get Customer By Id': props<{ id: string; mysid: string }>(),
    'Get Customer By Id Success': props<{ customer: Customer }>(),
    'Get Customer By Id Failure': props<{ error: any }>(),

    'Subscribe Single Institution': props<{
      mysid: string;
      relAcnId: string;
    }>(),
    'Unsubscribe Single Institution': props<{ mysid: string }>(),

    'Get Single Institution Success': props<{ institution: Customer }>(),
    'Get Single Institution Failure': props<{ error: any }>(),
  },
});
