import { createFeature, createReducer, on } from '@ngrx/store';
import { RelationGroup } from '@qtek/core/api-core';
import { LanguageOption } from '@qtek/shared/models';
import { META_CORE_KEY, Role, metaCoreInitialState } from '../models';
import { MetaCoreActions } from './meta-core.actions';

interface BaseCollection {
  id: string;
  name: string;
}

const extractKeys = <T extends BaseCollection>(
  payload: Record<string, string>
): T[] => {
  return Object.keys(payload).map(
    id =>
      ({
        id,
        name: payload[id],
      }) as T
  );
};

export const MetaCoreReducer = createReducer(
  metaCoreInitialState,
  on(MetaCoreActions.loadMetaSuccess, (state, { meta }) => {
    return {
      ...state,
      // ...(meta.unts && { units: extractKeys<Unit>(meta.unts) }),
      // ...(meta.frq && { frequencies: extractKeys<Frequency>(meta.frq) }),
      ...(meta.ptlRoleGroups && {
        roles: {
          user: extractKeys<Role>(
            meta.ptlRoleGroups[RelationGroup.USER]['roles']
          ),
          vendor: extractKeys<Role>(
            meta.ptlRoleGroups[RelationGroup.VENDOR]['roles']
          ),
          customer: extractKeys<Role>(
            meta.ptlRoleGroups[RelationGroup.CUSTOMER]['roles']
          ),
          staff: extractKeys<Role>(
            meta.ptlRoleGroups[RelationGroup.STAFF]['roles']
          ),
        },
      }),
      ...(meta.colors && { colors: meta.colors }),
      ...(meta.lngs && { languages: extractKeys<LanguageOption>(meta.lngs) }),
      ...(meta.ptl && {
        title1: meta.ptl.title1,
        title2: meta.ptl.title2,
        guiDomain: meta.ptl.guiDomain,
        guiOnlineBookDomain: meta.ptl.guiOnlineBookDomain,
        guiShrDomain: meta.ptl.guiShrDomain,
        docGuiDomain: meta.ptl.docGuiDomain,
        authGuiDomain: meta.ptl.authGuiDomain,
      }),
      ...(meta.phns && meta.phns.cntrs && { countryCode: meta.phns.cntrs }),
      // ...(meta.tzs && {
      //   timezones: Object.keys(meta.tzs).map((key) => ({
      //     timezone: key,
      //     names: meta.tzs[key],
      //   })),
      // }),
      ...(meta.wsPingPong && {
        wsPingPong: meta.wsPingPong,
      }),
      ...(meta.token && {
        token: meta.token,
      }),
      // ...(meta.licCmp && {
      //   licCmp: meta.licCmp,
      // }),
      // ...(meta.licWebId && {
      //   licWebId: meta.licWebId,
      // }),
    };
  }),
  on(MetaCoreActions.loadMetaError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export const MetaCoreFeature = createFeature({
  name: META_CORE_KEY,
  reducer: MetaCoreReducer,
});
