import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { BackendResponse } from '@qtek/shared/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userModels$ = new BehaviorSubject<BackendResponse | null>(null);

  constructor(private http: HttpClient) {}

  /**
   * Get data such as company, person, relations of currently logged in user.
   *
   * TODO: Add generic type to return value.
   *
   * @returns {Observable<BackendResponse>}
   *
   * @memberof PublicService
   */
  getCurrentUser(payload: any = {}): Observable<BackendResponse> {
    let params = new HttpParams();
    let headers = new HttpHeaders();

    if (payload.params && payload.params.prsId) {
      params = params.set('prsId', payload.params.prsId);
    }

    /**
     * Adding this header allows to redirect in auth guard instead of {@link RevalidateInterceptor}
     */
    if (payload.skipRevalidateErrorHandling) {
      headers = headers.append('X-Skip-Revalidate-Error-Handling', '');
    }
    return this.http.get<BackendResponse>('/api/v1/service/usr', {
      withCredentials: true,
      params,
      headers,
    });
  }

  /**
   * Check if user of given email address exists in database.
   *
   * NOTE: wrong jsdoc return type?
   *
   * @param {string} email Email address to be checked.
   * @param {string} tp Additional type
   * @returns {Observable<BackendResponse<{exist: boolean}>>} Observable of backend
   * response representing status of email.
   *
   * @memberof UserService
   */
  checkUniqueEmail(email: string, tp?: string, webId?: string) {
    let params = new HttpParams().set('email', email);

    if (tp) {
      params = params.append('tp', tp);
    }

    if (webId) {
      params = params.append('webId', webId);
    }

    // TODO add interface
    return this.http.get<{
      exist: boolean;
      ursLead: boolean;
      gdprFlg: boolean;
      rels?: Array<{ name: string; ent: string }>;
      gmtOffset?: number;
    }>('/api/v1/service/prs', { params });
  }

  checkAccountByEmail(email: string): Observable<{
    exist: boolean;
    contacts?: any;
    domainStatus: 'invalid' | 'excluded';
  }> {
    const params = new HttpParams().set('e', btoa(email));

    return this.http.get<any>('/api/v1/service/acn', {
      withCredentials: true,
      params,
    });
  }

  checkUniqueEmailForLogged(email: string): Observable<{
    exist: boolean;
    gdprFlg: boolean;
    ursLead: boolean;
  }> {
    const params = new HttpParams().set('email', email);

    return this.http.get<any>('/api/v1/service/prs', {
      withCredentials: true,
      params,
    });
  }

  getPublicDataOfOtherUser(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.get<BackendResponse>('/api/v1/service/usr', {
      withCredentials: true,
      params,
    });
  }

  getUsersModel(): Observable<BackendResponse> {
    return this.http
      .get<BackendResponse>('/api/v1/model/usrs', {
        withCredentials: true,
      })
      .pipe(tap(models => this.userModels$.next(models)));
  }

  /**
   * Get list of user's cmps
   *
   */
  getCompanies() {
    return this.http.get<BackendResponse>('/api/v1/e/relcmps', {
      withCredentials: true,
    });
  }
}
