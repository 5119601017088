import { InjectionToken } from '@angular/core';
import { filter, merge, mergeMap, Observable, take } from 'rxjs';

export const WEBSOCKET_BASE_URL = new InjectionToken<string>(
  'WEBSOCKET_BASE_URL'
);

export type WebsocketOpTypes =
  | 'init'
  | 'ping'
  | 'pong'
  | 'sub'
  | 'unsub'
  | 'query'
  | 'get'
  | 'del'
  | 'upd'
  | 'upds'
  | 'ins'
  | 'kill';

export type WebsocketEntTypes =
  | 'prs'
  | 'inv'
  | 'acn'
  | 'cmp'
  | 'rel'
  | 'itm'
  | 'itmext'
  | 'gnj'
  | 'gnjitm'
  | 'doc'
  | 'docref'
  | 'docmrp'
  | 'ctg'
  | 'clbitm'
  | 'pmt'
  | 'mtg'
  | 'eqp'
  | 'ddb'
  | 'loc'
  | 'avl'
  | 'web'
  | 'usr'
  | 'relcnt'
  | 'ccr'
  | 'bil'
  | 'cre'
  | 'agt'
  | 'avlcap'
  | 'i18n'
  | 'loctbl'
  | 'clb'
  | 'clbmbr'
  | 'par'
  | 'relvnd'
  | 'dsh'
  | 'not'
  | 'notusr'
  | 'notacn'
  | 'acndom'
  | 'parcnt'
  | 'parprf'
  | 'docacs'
  | 'itmtra'
  | 'mta'
  | 'etlmap'
  | 'tag';

export type WebsocketCmdTypes = 'query';

export interface BaseWebsocketMessage {
  op: WebsocketOpTypes;
  ent?: WebsocketEntTypes;
  mysid?: string;
}

//region Ping Pong Messages Begin
export interface PingWebsocketMessageRequest extends BaseWebsocketMessage {
  op: 'ping';
  id: number;
}

export interface PingWebsocketMessageResponse extends BaseWebsocketMessage {
  op: 'pong';
  id: number;
}

export function isPingWSRequest(
  message: BaseWebsocketMessage
): message is PingWebsocketMessageRequest {
  return message.op === 'ping';
}

export function isPingWSResponse(
  message: BaseWebsocketMessage
): message is PingWebsocketMessageResponse {
  return message.op === 'pong';
}
//endregion Ping Pong Messages End

//region Kill Messages Begin
export interface KillWebsocketMessage extends BaseWebsocketMessage {
  op: 'kill';
}

export function isKillWS(
  message: BaseWebsocketMessage
): message is KillWebsocketMessage {
  return message.op === 'kill';
}

//endregion Kill Messages End

//region Init Messages Begin
export interface ResInitWSMessage {
  prs?: { prsId: string };
  cmp?: { _id: string };
  wsTkn?: string;
}

export interface InitWebsocketMessageRequest extends BaseWebsocketMessage {
  op: 'init';
  v: string;
  res?: ResInitWSMessage;
}

export interface InitWebsocketMessageResponse extends BaseWebsocketMessage {
  op: 'init';
  v: string;
  ppInt: number;
}

export function isInitWS(
  message: BaseWebsocketMessage
): message is InitWebsocketMessageRequest | InitWebsocketMessageResponse {
  return message.op === 'init';
}

export function isInitWSRequest(
  message: BaseWebsocketMessage
): message is InitWebsocketMessageRequest {
  return isInitWS(message) && !(message as InitWebsocketMessageResponse).ppInt;
}

export function isInitWSResponse(
  message: BaseWebsocketMessage
): message is InitWebsocketMessageResponse {
  return isInitWS(message) && !!(message as InitWebsocketMessageResponse).ppInt;
}
//endregion Init Messages End

//region Sub Messages Begin
export interface QSubWSMessage {
  k: string;
  op: 'EQ' | 'NQ';
  value?: (string | number)[];
  v?: (string | number)[];
}

export interface SubWebsocketMessageRequest extends BaseWebsocketMessage {
  op: 'sub';
  ent: WebsocketEntTypes;
  cmd?: WebsocketCmdTypes;
  q?: QSubWSMessage[];
  id?: string;
  view?: string;
  mysid?: string;
  s?: string;
  prms?: Record<string, string>;
}

export function isSubWSRequest(
  message: BaseWebsocketMessage
): message is SubWebsocketMessageRequest {
  return message.op === 'sub';
}
//endregion Sub Messages End

//region Unsub Messages Begin
export interface UnsubWebsocketMessageRequest extends BaseWebsocketMessage {
  op: 'unsub';
  ent: WebsocketEntTypes;
  mysid?: string;
  view?: string;
}

export function isUnsubWSRequest(
  message: BaseWebsocketMessage
): message is SubWebsocketMessageRequest {
  return message.op === 'unsub';
}
//endregion Unsub Messages End

//region Query Messages Begin
export interface QueryWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'query';
  ent: WebsocketEntTypes;
  prms?: Record<string, any>;
  myid?: string;
  id?: string;
  q?: any;
  mysid?: string;
  view?: string;
  s?: string;
}

export interface QueryWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'query';
  ent: WebsocketEntTypes;
  res: T[];
  mysid?: string;
  sts?: Record<string, any>;
}

export function isQueryWS(
  message: BaseWebsocketMessage
): message is QueryWebsocketMessageRequest | QueryWebsocketMessageResponse {
  return message.op === 'query';
}

export function isQueryWSRequest(
  message: BaseWebsocketMessage
): message is QueryWebsocketMessageRequest {
  return isQueryWS(message) && !!(message as QueryWebsocketMessageRequest).prms;
}

export function isQueryWSResponse<T = any>(
  message: BaseWebsocketMessage
): message is QueryWebsocketMessageResponse<T> {
  return (
    isQueryWS(message) && !!(message as QueryWebsocketMessageResponse<T>).res
  );
}
//endregion Query Message End

//region Del Message Begin
export interface DelWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'del';
  ent: WebsocketEntTypes;
  id: string | number;
  prms?: Record<string, string>;
  myid?: string;
  mysid?: string;
}

export interface DelWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'del';
  ent: WebsocketEntTypes;
  id: string;
  res?: T;
  sts?: any;
}

export function isDelWS(
  message: BaseWebsocketMessage
): message is DelWebsocketMessageRequest | DelWebsocketMessageResponse {
  return message.op === 'del';
}

export function isDelWSRequest(
  message: BaseWebsocketMessage
): message is DelWebsocketMessageRequest {
  return (
    isDelWS(message) &&
    !(
      (message as DelWebsocketMessageResponse).res ||
      (message as DelWebsocketMessageResponse).sts
    )
  );
}

export function isDelWSResponse<T = any>(
  message: BaseWebsocketMessage
): message is DelWebsocketMessageResponse {
  return (
    isDelWS(message) &&
    !!(
      (message as DelWebsocketMessageResponse).res ||
      (message as DelWebsocketMessageResponse<T>).sts
    )
  );
}
//endregion Del Message End

//region Upd Message Begin
export interface UpdWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'upd';
  ent: WebsocketEntTypes;
  res: T;
  id?: string | number;
  prms?: Record<string, string>;
  myid?: string;
  mysid?: string;
}

export interface UpdWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'upd';
  ent: WebsocketEntTypes;
  id: string;
  res?: T;
  sts?: any;
}

export function isUpdWS<T = any>(
  message: BaseWebsocketMessage
): message is UpdWebsocketMessageRequest<T> | UpdWebsocketMessageResponse<T> {
  return message.op === 'upd';
}
//endregion Upd Message End

//region Get Message Begin
export interface GetWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'get';
  ent: WebsocketEntTypes;
  id?: string;
  prms?: Record<string, string>;
  myid?: string;
  mysid?: string;
  view?: string;
}

export interface GetWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'get';
  ent: WebsocketEntTypes;
  id: string;
  res?: T;
  sts?: any;
  view?: string;
}

export function isGetWS<T = any>(
  message: BaseWebsocketMessage
): message is GetWebsocketMessageRequest<T> | GetWebsocketMessageResponse<T> {
  return message.op === 'get';
}
//endregion Get Message End

//region Upds Message Begin
export interface UpdsWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'upds';
  ent: WebsocketEntTypes;
  res: T;
  id?: string;
  prms?: Record<string, string>;
  myid?: string;
  mysid?: string;
}

export interface UpdsWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'upds';
  ent: WebsocketEntTypes;
  id: string;
  res?: T[];
  sts?: any;
}

export function isUpdsWS<T = any>(
  message: BaseWebsocketMessage
): message is UpdsWebsocketMessageRequest<T> | UpdsWebsocketMessageResponse<T> {
  return message.op === 'upds';
}
//endregion Upds Message End

//region Ins Message Begin
export interface InsWebsocketMessageRequest<T = any>
  extends BaseWebsocketMessage {
  op: 'ins';
  ent: WebsocketEntTypes;
  res: T;
  id?: string;
  prms?: Record<string, string>;
  myid?: string;
  mysid?: string;
}

export interface InsWebsocketMessageResponse<T = any>
  extends BaseWebsocketMessage {
  op: 'ins';
  ent: WebsocketEntTypes;
  id: string;
  res?: T;
  sts?: any;
}

export function isInsWS<T = any>(
  message: BaseWebsocketMessage
): message is InsWebsocketMessageRequest<T> | InsWebsocketMessageResponse<T> {
  return message.op === 'ins';
}
//endregion Ins Message End

//region Errors Messages Begin
export interface ErrorMessageResponse extends BaseWebsocketMessage {
  sts: {
    sts: 'ERR' | 'WARN';
    attrs: Record<
      string,
      { main: boolean; sts: 'ERR' | 'WARN'; prms: string[]; msg: string }
    >;
  };
}

export function isWSErrorResponse(
  message: BaseWebsocketMessage
): message is ErrorMessageResponse {
  return (
    Object.keys((message as ErrorMessageResponse).sts?.attrs ?? {}).length > 0
  );
}

export function isWSUnauthorized(message: ErrorMessageResponse): boolean {
  if (message.sts && message.sts.sts === 'WARN' && message.ent !== 'i18n') {
    const isUnauthorized =
      JSON.stringify(message.sts).indexOf('unauthorized') >= 0;
    return isUnauthorized;
  } else {
    return false;
  }
}

//endregion Errors Messages End
export function passWhenAlive<T>(isAlive$: Observable<boolean>) {
  return function <T>(source: Observable<T>) {
    return isAlive$.pipe(
      filter(isAlive => isAlive),
      take(1),
      mergeMap(() => source)
    );
  };
}

export function waitForResponseOrPassedEmit<T, K = unknown>(
  passedObs$: Observable<K>
) {
  return function <T>(source: Observable<T>) {
    return merge(source, passedObs$).pipe(take(1));
  };
}
